<template>
  <div>
    <v-row no-gutters justify="center" v-if="isLoading" class="py-10">
      <VProgressCircular color="primary" size="80" indeterminate/>
    </v-row>
    <template v-if="paginator">
      <v-row id="products-filter-result-wrapper" v-if="!isLoading">
        <v-col cols="12" :md="hasListView ? 12 : 6" :key="product.id" v-for="product in paginator.data">
          <ProductsCard :product="product" v-if="!hasListView"/>
          <ProductsListItem :product="product" v-else/>
        </v-col>
        <v-col cols="12" v-if="paginator.data.length === 0">
          <ProductsEmpty/>
        </v-col>
      </v-row>
      <VPagination :length="paginator.meta.lastPage" v-model="currentPage" class="ma-3"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fetchAll } from '@/api/product/product.js';
import Paginator from '@/mixins/Paginator.js';
import ProductsListItem from './ProductListItem.vue';
import ProductsCard from './ProductsCard.vue';
import { Product } from '@/application/models/Product.js';
import ProductsEmpty from '@/components/Products/Empty.vue';

export default {
  name: 'ProductsFilterResult',
  components: {
    ProductsEmpty,
    ProductsCard,
    ProductsListItem,
  },
  mixins: [Paginator],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    products: [],
    isLoading: false,
    hasListView: false,
    currentPage: 1,
  }),
  computed: {
    ...mapGetters({
      selectedBrand: 'filter/selectedBrand',
      selectedModel: 'filter/selectedModel',
      selectedImplementation: 'filter/selectedImplementation',
    }),
  },
  watch: {
    currentPage: {
      handler(val) {
        this.loadPaginator(val);
      },
    },
    filters: {
      handler() {
        this.hasListView = this.filters.productGroupId === 0;

        if (this.currentPage === 1) {
          this.loadPaginator(this.currentPage);
        } else {
          this.currentPage = 1;
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadPaginator(this.currentPage);
  },
  methods: {
    async loadPaginator(page, sortBy, sortDesc) {
      this.isLoading = true;

      this.paginator = await fetchAll(page,
          this.perPage,
          this.$route.query.q,
          sortBy || this.sortBy,
          sortDesc,
          {
            materialId: this.filters.materialId,
            productGroupId: this.filters.productGroupId !== 0 ? this.filters.productGroupId : undefined,
            implementationId: this.selectedImplementation ? this.selectedImplementation.id : undefined,
          });

      this.paginator.data = this.paginator.data.map(data => {
        return new Product().mapResponse(data);
      });

      this.isLoading = false; // loading changes
      this.pagination.page = this.paginator.meta.currentPage;
    },
  },
};
</script>

<style scoped>

</style>
