<template>
  <div>
    <v-menu
        v-model="menu"
        transition="slide-x-reverse-transition"
        left
        :nudge-left="10"
        offset-x
        z-index="4"
    >
      <template v-slot:activator="{ on }">
        <v-text-field v-model="code" outlined label="Ga naar het artikel" placeholder="Artikelnummer"/>
        <div v-on="on"></div>
      </template>

      <v-alert type="error" class="mb-0" border="right" v-model="alert" dismissible>
        Er bestaat geen product met de ingevulde code
      </v-alert>
    </v-menu>

    <v-btn block color="primary" @click="searchArticle" :loading="loading">
      <v-icon class="mr-3">fas fa-tag</v-icon>
      Toon artikel
    </v-btn>
  </div>
</template>

<script>
import { fetch } from '../api/product/product.js';

export default {
  name: 'ArticleSearcher',
  data() {
    return {
      code: '',
      loading: false,
      menu: false,
      alert: true,
    };
  },
  methods: {
    async searchArticle() {
      if (this.code === '') {
        return;
      }

      this.loading = true;
      this.menu = false;
      this.alert = true;

      const { data } = await fetch(this.code);

      this.loading = false;

      if (data.length === 0) {
        this.menu = true;
        return;
      } else if (data[0].code === this.$route.params.code) {
        this.code = '';
        return;
      }

      this.$router.push({
        name: 'product',
        params: {
          code: this.code,
        },
      });
      this.code = '';
    },
  },
};
</script>
