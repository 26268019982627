export default {
  data() {
    return {
      paginator: null,
      paginationIsLoaded: false,
      pagination: {
        page: 1,
        sortBy: '',
        sortDesc: '',
      },
    };
  },
  methods: {

    /**
     * Update only the page of the pagination object
     * @param page
     */
    updatePaginationPage(page) {
      this.updatePagination({
        page: page,
        sortBy: [this.pagination.sortBy],
        sortDesc: [this.pagination.sortDesc],
      });
    },
    /**
     * Update the pagination object and then reload the paginated resource with the new pagination object.
     * With a new sort, or a new page
     * @param sortBy
     * @param sortDesc
     * @param page
     */
    updatePagination({
      sortBy,
      sortDesc,
      page,
    }) {
      this.pagination = {
        page: page,
        sortBy: sortBy && sortBy.length ? sortBy[0] : undefined,
        sortDesc: sortDesc && sortDesc.length ? sortDesc[0] : undefined,
      };

      if (this.paginationIsLoaded) {
        return this.reloadPaginator();
      }

      this.paginationIsLoaded = true;
    },
    /**
     * Call the loadPaginator with the page and sorter saved in the pagination object
     */
    reloadPaginator() {
      this.loadPaginator(this.pagination.page, this.pagination.sortBy, this.pagination.sortDesc, this.filters);
    },
  },
};
