<template>
  <div class="order">
    <v-row class="justify-center" no-gutters>
      <v-col cols="12" md="12" lg="9" xl="8" class="pa-3">
        <v-row>
          <v-col lg="3">
            <ProductsFilter v-model="filters"/>
          </v-col>
          <v-col md="9">
            <ProductsFilterResult :filters="filters"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductsFilter from '../components/Products/ProductsFilter.vue';
import ProductsFilterResult from '../components/Products/ProductsFilterResult.vue';

export default {
  name: 'Products',
  components: {
    ProductsFilterResult,
    ProductsFilter,
  },
  computed: {
    ...mapGetters({
      selectedMaterialId: 'filter/selectedMaterialId',
    }),
  },
  created() {
    this.$hero.$emit('setTitle', '');
    this.$hero.$emit('setBreadcrumbs', [
      {
        text: 'home',
        exact: true,
        to: { name: 'home' },
      },
      {
        text: 'assortiment',
        to: { name: 'order' },
      },
      {
        text: 'uw keuze',
        to: { name: 'products' },
      },
    ]);
    this.$hero.$emit('setSelectedArticles', [1, 2, 3]);
    this.$hero.$emit('setLarge');

    if (this.selectedMaterialId) {
      this.filters.materialId = this.selectedMaterialId;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$hero.$emit('setSmall');
    this.$hero.$emit('setSelectedArticles', null);
    next();
  },
  data: () => ({
    filters: {},
  }),
};
</script>
