<template>
  <v-btn v-bind="{...$attrs, loading: loadingProp}" v-on="$listeners">
    <template v-slot:loader>
      <v-icon v-if="showCheck" class="check-icon">far fa-check</v-icon>
      <v-progress-circular indeterminate size="23" :width="2" v-else/>
    </template>
    <slot>Save</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AffirmativeButton',
  props: {
    loading: {
      type: Boolean,
    },
    canBeDisabled: {
      type: Boolean,
    },
    message: {
      type: String,
      required: true,
    },
  },
  watch: {
    loading(isLoading) {
      if (isLoading) {
        this.startLoading();
        return;
      }
      this.checkStopLoading();
    },
  },
  data() {
    return {
      loadingProp: false,
      loadingStartTime: null,
      showCheck: false,
    };
  },
  methods: {
    startLoading() {
      this.loadingStartTime = new Date().valueOf();
      this.loadingProp = true;
    },
    checkStopLoading() {
      const timeDifference = new Date().valueOf() - this.loadingStartTime;
      if (timeDifference < 500) {
        setTimeout(() => {
          this.$hero.$emit('setSnackbar', {
            text: this.message,
            color: 'success',
          });
          this.stopLoading();
        }, 500 - timeDifference);
        return;
      }
      this.stopLoading();
    },
    stopLoading() {
      this.showCheck = true;
      setTimeout(() => {
            this.showCheck = false;
            this.loadingProp = false;
          }, 1500,
      );
    },
  },
};
</script>

<style scoped>
.check-icon {
  color: inherit;
}
</style>
