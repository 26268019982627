<template>
  <v-card outlined>
    <VImg contain :src="media" aspect-ratio="3" width="100%"/>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-body-2">Artikel nummer: {{ product.code }}</div>
          <div class="text-body-2">Levertijd: 2-3 dagen</div>
          <div class="text-h6">{{ product.name }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="8">
          <div v-html="product.description"></div>
        </v-col>
        <v-col cols="12" lg="4">
          <div class="text-body-2 text-right strike-through" v-if="product.alternativePrice">
            {{ product.alternativePrice | currency }}
          </div>
          <div class="text-h6 text-right">{{ product.price | currency }}</div>
          <div class="text-body-2 text-right">Excl BTW</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <VSpacer/>
      <v-btn text color="primary" :to="{name: 'product', params: {code: product.code}}">
        <v-icon class="mr-3">far fa-info-circle</v-icon>
        toon
      </v-btn>
      <affirmative-button color="primary"
                          :loading="loading"
                          @click="handleAddOrder"
                          message="Product is toegevoegd aan uw winkelwagen">
        <v-icon class="mr-3">far fa-shopping-cart</v-icon>
        Voeg toe
      </affirmative-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import AffirmativeButton from '../AffirmativeButton.vue';
import { mapMutations } from 'vuex';
import { getPlaceholder } from '@/application/util/imagePlaceholder.js';

export default {
  name: 'ProductsCard',
  components: { AffirmativeButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    media() {
      return this.product?.mainImage?.url ?? getPlaceholder();
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapMutations('order', ['addOrderLine']),
    handleAddOrder() {
      this.loading = true;

      this.addOrderLine(this.product);

      this.$nextTick(() => this.loading = false);
    },
  },
};
</script>
