import Model from './model';

export class Product extends Model {
  id = 0;
  name = '';
  code = '';
  description = '';
  implementation = null;
  material = null;
  mainImage = null;
  images = [];
  productGroup = null;
  stock = 0;
  price = 0;
  alternativePrice = 0;

  mapForRequest() {
    return this;
  }

  mapResponse(data) {
    return super.mapResponse({
      ...data,
      price: (data.price / 100).toFixed(2),
      alternativePrice: !!data.alternativePrice && data.alternativePrice !== data.price
        ? (data.alternativePrice / 100).toFixed(2) : 0,
    });
  }
}
