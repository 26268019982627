<template>
  <v-card>
    <v-card-title class="text-h6 pb-0">
      {{ product.productGroup.name }}
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="8">
          <div class="text-body-2">Artikel nummer: {{ product.code }}</div>
          <div v-html="product.description"></div>
        </v-col>
        <v-col cols="4">
          <div class="text-body-2 text-right strike-through" v-if="product.alternativePrice">
            {{ product.alternativePrice | currency }}
          </div>
          <div class="text-h6 text-right">{{ product.price | currency }}</div>
          <div class="text-body-2 text-right">Excl BTW</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <p class="ma-0">
        <v-icon>far fa-truck</v-icon>
        <span class="font-weight-bold ml-2">Levertijd</span>
        2 tot 3 dagen
      </p>
      <VSpacer/>
      <v-btn text color="primary" :to="{name: 'product', params: {code: product.code}}">
        <v-icon class="mr-3">far fa-info-circle</v-icon>
        toon
      </v-btn>
      <affirmative-button color="primary"
                          :loading="loading"
                          @click="handleAddOrder"
                          message="Product is toegevoegd aan uw winkelwagen">
        <v-icon class="mr-3">far fa-shopping-cart</v-icon>
        Voeg toe
      </affirmative-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import AffirmativeButton from '../AffirmativeButton.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'ProductListItem',
  components: { AffirmativeButton },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapMutations('order', ['addOrderLine']),
    handleAddOrder() {
      this.loading = true;

      this.addOrderLine(this.product);

      this.$nextTick(() => this.loading = false);
    },
  },
};
</script>

<style scoped>

</style>
