<template>
  <v-card tile elevation="0">
    <v-card-text class="text-center">
      <v-avatar rounded size="100">
        <v-icon size="65">fas fa-exclamation</v-icon>
      </v-avatar>
      <p>Er zijn geen producten gevonden onder het geselecteerde merk, model en uitvoering.</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProductsEmpty',
};
</script>
