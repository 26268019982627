<template>
  <v-card :min-width="210">
    <v-card-text>
      <ArticleSearcher class="mb-5"/>
      <v-label>Groep</v-label>
      <v-radio-group v-model="filters.productGroupId">
        <VRadio v-for="(productGroup, index) in productGroups"
                :key="'G'+index"
                :label="productGroup.name"
                :value="productGroup.id"/>
      </v-radio-group>

      <v-label>Categorie</v-label>
      <v-radio-group v-model="filters.materialId">
        <VRadio v-for="(material, index) in materials"
                :key="'M'+index"
                :label="material.name"
                :value="material.id"
        />
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import ArticleSearcher from '../ArticleSearcher.vue';
import {fetch as fetchMaterials} from '../../api/product/material.js';
import {fetch as fetchProductGroup} from '../../api/product/productGroup.js';

export default {
  name: 'ProductsFilter',
  components: {ArticleSearcher},
  props: ['value'],
  data: () => ({
    materials: [],
    productGroups: [],
    filters: {},
  }),
  created() {
    this.getMaterials();
    this.filters = this.value;
    this.getProductGroup();
  },
  watch: {
    filters: {
      handler() {
        this.$emit('changed', this.filters);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getMaterials() {
      const materialRequest = await fetchMaterials();
      this.materials = materialRequest.data ? materialRequest.data.data : [];
    },
    async getProductGroup() {
      const productGroupRequest = await fetchProductGroup();
      this.productGroups = productGroupRequest.data ? productGroupRequest.data.data : [];
      this.productGroups.push({
        name: 'Alles',
        id: 0,
      });
    },
  },
};
</script>
